<template>
	<div class="common-card">
		<div class="common-card__header">
			<span v-if="content.city" class="common-card__city">
				{{ content.city?.title }}
			</span>
			<span class="common-card__title">{{ content.title }}</span>
		</div>

		<div class="common-card__bg-image">
			<or-image
				v-if="!isLoading"
				:src="content.logo"
				:width="imageSizes.width"
				:height="imageSizes.height"
				:alt="options?.alt || 'Example areas'"
			/>
			<or-skeleton v-else style="max-width: 142px; width: 100%; height: 38px" />
		</div>

		<div class="common-card__content">
			<span class="common-card__price">{{ price }}</span>
			<p class="common-card__offers">
				<span class="common-card__offers--count">
					{{ content.announcement_count }}
				</span>
				{{ $t('general.offers') }}
			</p>
		</div>
	</div>
</template>

<script>
import { money, simbol } from '@/enums/moneyСollections.js'
import { formatCash } from '@/utils/formatMoney'

export default {
	props: {
		content: {
			type: Object,
			default: () => ({})
		},
		isLoading: {
			type: Boolean
		},
		imageSizes: {
			type: Object,
			default: () => ({
				width: 280
				// height: 175
			})
		},
		options: {
			type: Object
		}
	},
	computed: {
		price() {
			if (this.content?.price_from) {
				const from = `${simbol[this.$store.state.activeMoney]}${formatCash(
					this.content.price_from[money[this.$store.state.activeMoney]]
				)}`
				let to = ``
				if (this.content?.price_to?.aed > 0)
					to = ` — ${simbol[this.$store.state.activeMoney]}${formatCash(
						this.content.price_to[money[this.$store.state.activeMoney]]
					)}`
				return `${from}${to}`
			} else if (this.content?.price) {
				return `${simbol[this.$store.state.activeMoney]}${formatCash(
					this.content.price[money[this.$store.state.activeMoney]]
				)}`
			} else return ''
		}
	}
}
</script>

<style lang="scss">
.common-card {
	position: relative;
	cursor: pointer;
	overflow: hidden;
	width: 100%;

	&__header {
		position: absolute;
		padding-top: 15px;
		padding-left: 13px;
		text-align: left;
		z-index: 1;
	}

	&__bg-image {
		position: relative;

		img {
			width: 100%;
			aspect-ratio: 16 / 10;
			height: 100%;
		}
		.skeleton-basic {
			width: 100%;
			height: unset;
			aspect-ratio: 16 / 10;
		}
		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.2) 0%,
				rgba(0, 0, 0, 0) 100%
			);
		}
	}

	&__city {
		padding: 5px 10px;
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 4px;
	}

	&__title {
		display: block;
		margin: 0;
		margin-top: 10px;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		color: #ffffff;
		text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
	}

	&__bg-image img {
		object-fit: cover;

		@media (max-width: 540px) {
			width: 100%;
		}
	}

	&__content {
		margin-top: 15px;
		text-align: left;
	}

	&__price {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 19px;
	}

	&__offers {
		margin: 0;
		margin-top: 5px;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		color: #929292;

		&--count {
			font-weight: 700;
			color: #c99d56;
		}
	}
}
</style>
